import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import PdfButton from "../ui/pdf-button";
import SecondaryLinks from "../ui/secondary-links";
import Button from "../ui/button";
import ApplyNow from "../ui/apply-now-button";
import ScheduleTour from "../ui/schedule-button";
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { useEffect } from "react";
import {
  Wrapper,
  Logo,
  Col,
  Center,
  Address,
  Links,
  LinkItem,
  WLogo,
  Icons,
  Icon,
  Row,
  Cookie,
} from "./index.styled";

const Footer = () => {
  const {
    dataJson: {
      footerInfo: { address, phone, links, instalink },
    },
  } = useStaticQuery(graphql`
    query {
      dataJson {
        footerInfo {
          address
          phone
          instalink
          links {
            title
            link
          }
        }
      }
    }
  `);

  useEffect(() => {
    const loadScript = (src, id) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.async = true;
        script.onload = () => resolve(script);
        script.onerror = (error) => reject(error);
        document.head.appendChild(script);
      });
    };

    const loadDniScript = () => {
      const dniScript = document.createElement("script");
      dniScript.innerHTML = `
        function call_dni() {
          if (typeof dni_format === 'undefined') {
              dni_format = "xxx.xxx.xxxx";
          }
          var propertyid = "1314357";
          var format = dni_format;
          if (typeof call_dni_function == 'function') {
              call_dni_function(propertyid, format);
          }
        }
        call_dni();
      `;
      dniScript.id = "dniscript-js";
      document.head.appendChild(dniScript);
    };

    const initializeScripts = async () => {
      try {
        // Load jQuery
        await loadScript(
          "https://code.jquery.com/jquery-3.7.0.min.js",
          "jQuery"
        );

        // Load DNI script
        await loadScript(
          "https://multifamily.hinesdni.com/js/dni.js",
          "DNI-js"
        );

        // Once both scripts are loaded, call the DNI script
        loadDniScript();
      } catch (error) {
        console.error("Error loading scripts:", error);
      }
    };

    // Initialize scripts when the component mounts
    initializeScripts();

    return () => {
      // Clean up scripts when the component unmounts
      const existingScript = document.getElementById("DNI-js");
      if (existingScript) {
        document.head.removeChild(existingScript);
      }
      const existingDni = document.getElementById("dniscript-js");
      if (existingDni) {
        document.head.removeChild(existingDni);
      }
      const existingjQuery = document.getElementById("jQuery");
      if (existingjQuery) {
        document.head.removeChild(existingjQuery);
      }
    };
  }, []);

  return (
    <>
      <Wrapper className={"hideMob"}>
        <Col>
          <PdfButton />
          <WLogo to="/" />
        </Col>
        <Center>
          <Logo />
          <Address>
           <a target="_blank" href="https://www.google.com/maps/place/Maple+Terrace+Residences/@32.7976749,-96.8111731,935m/data=!3m2!1e3!4b1!4m6!3m5!1s0x864e993157cdf7e9:0x970c835e9e45c1c9!8m2!3d32.7976749!4d-96.8085982!16s%2Fg%2F1wl4q__j?entry=ttu&g_ep=EgoyMDI1MDMxOC4wIKXMDSoASAFQAw%3D%3D">{address}</a>
            <br></br>
            <a className="dni_tel_link dni_phone_number" href={`tel:${phone}`}>
              {phone}
            </a>
          </Address>
        </Center>
        <Col className={"last"}>
          <SecondaryLinks />
          <button
      className="cmp-revoke-consent"
      style={{   color: '#151515',
position:'static',
        background:'none',
        appearance:'none',
        border:'none',
        padding:'0',
        cursor:'pointer',
       textTransform:'uppercase',
       fontFamily: '"TT Norms Pro 500"',
       fontWeight: '500',
       fontSize: `${calculateResponsiveSize(9)}`,
      }}
    >
      Manage Cookie Preferences
    </button>
          <Icons>
            <a href={instalink} target="_blank">
              <Icon className={"instagram"} />
            </a>
            <a
              href="https://www.hines.com/real-estate-services/product-types/living/americas-fair-housing-statement"
              target="_blank"
            >
              <Icon className={"housing"} />
            </a>
            <a href="https://www.hines.com/accessibility" target="_blank">
              <Icon className={"ada"} />
            </a>
            <Icon className={"no-smoking"} />
            <Icon className={"pet-friendly"} />
          </Icons>
        </Col>
      </Wrapper>
      <Wrapper className={"hideWeb"}>
        <Logo />
        <Row className={"center"}>
          <PdfButton />
          {/*  <ApplyNow className={['black'].join(' ')} />
          <ScheduleTour className={'black'} /> */}
        </Row>
        <Row>
          <Address>
           <a target="_blank" href="https://www.google.com/maps/place/Maple+Terrace+Residences/@32.7976749,-96.8111731,935m/data=!3m2!1e3!4b1!4m6!3m5!1s0x864e993157cdf7e9:0x970c835e9e45c1c9!8m2!3d32.7976749!4d-96.8085982!16s%2Fg%2F1wl4q__j?entry=ttu&g_ep=EgoyMDI1MDMxOC4wIKXMDSoASAFQAw%3D%3D">{address}</a>
            <br></br>
            <a className="dni_tel_link dni_phone_number" href={`tel:${phone}`}>
              {phone}
            </a>
          </Address>
          <SecondaryLinks />
          <button
      className="cmp-revoke-consent"
      style={{   color: '#151515',
position:'static',
        background:'none',
        appearance:'none',
        border:'none',
        padding:'0',
        cursor:'pointer',
       textTransform:'uppercase',
       fontFamily: '"TT Norms Pro 500"',
       fontWeight: '500',
       fontSize: '9px',
       paddingLeft:'5px',
       paddingTop:'3px'
      }}
    >
      Manage Cookie Preferences
    </button>
        </Row>
        <Row>
          <WLogo />
          <Icons>
            <a href={instalink} target="_blank">
              <Icon className={"instagram"} />
            </a>
            <a
              href="https://www.hines.com/real-estate-services/product-types/living/americas-fair-housing-statement"
              target="_blank"
            >
              <Icon className={"housing"} />
            </a>
            <a href="https://www.hines.com/accessibility" target="_blank">
              <Icon className={"ada"} />
            </a>
            <Icon className={"no-smoking"} />
            <Icon className={"pet-friendly"} />
          </Icons>
        </Row>
      </Wrapper>
    </>
  );
};

export default Footer;
