import { createGlobalStyle } from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import CloseIcon from '../icons/close.svg'

const GlobalStyle = createGlobalStyle`
     :root {
      --black: #151515;
      --text: #292929;
      --white: #fff;
      --beige: #DED8D0;
    } 
    li {
        list-style: none;
    }
    * {
      margin: 0;
      padding: 0;
      font-family: inherit;
      box-sizing: border-box;
    }
    body {
      background: var(--white);
      color: var(--text);
      &.overflow {
        overflow: hidden;
      }
    }
    @-webkit-keyframes mouse-over {
      0% {
        background-size: 0% 100%;
        background-position: 0% 100%;

      }
      100% {
        background-size: 100% 100%;
        background-position: 0% 100%;

      }
    }
    @-webkit-keyframes mouse-out {
      0% {
        background-size: 100% 100%;
        background-position: 100% 0%;

      }
      100% {
        background-size: 0% 100%;
        background-position: 100% 0%;
      }
    }
    .gallery-swiper.swiper{
      height: 100%;
    }
    .gallery-swiper .swiper-wrapper{
      height: calc(100% - 40px);
    }
    .gallery-swiper .swiper-slide{
      display: flex;
    }
 
  .portal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 103;
    display: flex;
    justify-content: center;
   
  }
  .cookies-banner {
    padding: 0 ${calculateResponsiveSize(30)};
    width: 100%;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(41, 41, 41, 0.90);
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    color: #FFF;
    font-family: "TT Norms Pro 300";
    font-size: ${calculateResponsiveSize(14)};
    font-style: normal;
    font-weight: 300;
    line-height: 120%; 
    height: ${calculateResponsiveSize(50)};

    animation: cookies 1s;
    ${mediaMax('mobile')} {
      padding: 0 15px;
      height: 50px;
      font-size: 14px;
      border-radius: 40px;
      width: auto;
      bottom: 20px;
      overflow: hidden;
      animation: none;
    }
  }
  .cookies-banner-decline-btn{
    -webkit-mask-image: url(${CloseIcon});
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-size: contain;
  
    mask-image: url(${CloseIcon});
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    background: white;
    border: none;
  
    height: ${calculateResponsiveSize(18)};
    width: ${calculateResponsiveSize(18)};
    cursor: pointer;
    text-indent: -1000px;
    position: absolute;
    right: 30px;
    ${mediaMax('mobile')} {
     display: none;
    }
  }
  .cookies-banner-accept-btn{
    background-color: white;
    color: #000;
    border: 1px solid white;
    text-align: center;
    font-family: 'TT Norms Pro 400';
    font-size: ${calculateResponsiveSize(12)};
    font-style: normal;
    font-weight: 400;
    line-height: ${calculateResponsiveSize(12)};
    padding: ${calculateResponsiveSize(6)};
    cursor: pointer;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    transition: 0.5s;
    border-radius: ${calculateResponsiveSize(36)};
    width: ${calculateResponsiveSize(120)};
    justify-content: center;
    margin-left: ${calculateResponsiveSize(20)};
    &:hover{
      background-color: #000;
      color: white;
      border: 1px solid #000;
    }
    ${mediaMax('mobile')} {
      border-radius: 36px;
      width: auto;
      justify-content: center;
      margin-left: 10px;
      font-size: 12px;
      line-height: 12px;
      padding: 6px 11px;
    }
  }
  .cookies-banner-btns{
    display: flex;
    align-items: center;
  }
  .cookies-banner-content{
    display: flex;
    align-items: center;
    white-space: break-spaces;
    ${mediaMax('mobile')} {
      white-space: pre;
    }
  }

  
  @keyframes cookies {
    0%   { height: ${calculateResponsiveSize(0)}; }
    100% { height: ${calculateResponsiveSize(50)}; }
}

.mapboxgl-canvas-container{
  ${mediaMax('mobile')} {
    width: 100vw;
  }
}

.fadeIn {
  transform: translateY(50px);
  opacity: 0;
  transition: all 1s ease;
  &.visible {
    transform: translateY(0);
    opacity: 1;
  }
}
`

export default GlobalStyle
